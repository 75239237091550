import styled from 'styled-components'
import media from 'styled-media-query'

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
  padding: 3.2rem;

  ${media.lessThan('large')`
    width: 100%;
  `}
`

export const CardInfo = styled.div`
  font-size: 1.6rem;
  line-height: 2.4rem;
  min-height: 10rem;
  color: ${props => props.theme.colors.secondary};
  margin: 0;
  margin-bottom: 2.4rem;

  ${media.lessThan('large')`
    min-height: auto;
  `}
`

export const CardButton = styled.a`
  text-decoration: none;
  width: 22rem;
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 1.3rem 0;
  font-size: 1.6rem;
  line-height: 2.4rem;
  background: ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.secondary};
  border: 1px solid ${props => props.theme.colors.secondary};
  border-radius: 2px;
  margin-right: 2.4rem;
  opacity: 0.8;

  &:hover {
    opacity: 1;
  }

  @media only screen and (max-width: 767px) {
    opacity: 1;
    width: 100%;
    margin-right: 0;
  }
`

export const ButtonsConatiner = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${media.lessThan('medium')`
    flex-wrap: wrap;
  `}
`

export const ButtonContainer = styled.div`
  @media (max-width: 1810px) and (min-width: 1171px) {
    &:last-of-type {
      margin-top: 2.4rem;
    }
  }

  @media (max-width: 1318px) and (min-width: 1171px) {
    &:nth-of-type(2) {
      margin-top: 2.4rem;
    }
  }

  @media only screen and (max-width: 1008px) {
    &:last-of-type {
      margin-top: 2.4rem;
    }
  }

  ${media.lessThan('medium')`
    width: 100%;

    &:last-of-type, &:nth-of-type(2) {
      margin-top: 3.2rem;
    }
  `}
`

export const CardWhatsappIcon = styled.img`
  width: 2.4rem;
  height: 2.4rem;
  margin-right: 0.6rem;
`

export const CardRegionName = styled.h3`
  font-family: 'Dasa Sans';
  font-weight: normal;
  font-size: 2rem;
  line-height: 2.4rem;
  margin: 0;
  margin-bottom: 1.2rem;
`
