import styled from 'styled-components'
import { KeyboardArrowDown } from '@styled-icons/material-rounded/KeyboardArrowDown'
import media from 'styled-media-query'

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
  padding: 3.2rem;
`

export const Label = styled.label`
  font-size: 1.4rem;
  line-height: 2.4rem;
  color: ${props => props.theme.colors.black};

  &::after {
    content: '*';
    margin-left: 0.3rem;
    color: ${props => ['inherit', 'red'][Number(props.error)]};
  }
`

export const InputWrapper = styled.div`
  width: 100%;
  margin-bottom: 2.4rem;

  &::last-of-type {
    margin-bottom: 4rem;
  }
`

export const InputText = styled.input`
  width: 100%;
  outline: none;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid ${props => props.theme.colors.brownishGrey};
  padding: 0.5rem 0;
  color: ${props => props.theme.colors.brownishGrey};
  font-size: 1.6rem;
  line-height: 2.4rem;

  ::-webkit-input-placeholder {
    font-family: 'Dasa Sans';
    font-style: italic;
    font-weight: normal;
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
  input:-moz-placeholder {
    font-family: 'Dasa Sans';
    font-style: italic;
    font-weight: normal;
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
  textarea:-moz-placeholder {
    font-family: 'Dasa Sans';
    font-style: italic;
    font-weight: normal;
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
`

export const InputTextArea = styled.textarea`
  font-family: 'Dasa Sans';
  width: 100%;
  outline: none;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid ${props => props.theme.colors.brownishGrey};
  color: ${props => props.theme.colors.brownishGrey};
  font-size: 1.6rem;
  line-height: 2.4rem;
  resize: none;
  padding: 0;

  ::-webkit-input-placeholder {
    font-family: 'Dasa Sans';
    font-style: italic;
    font-weight: normal;
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
  input:-moz-placeholder {
    font-family: 'Dasa Sans';
    font-style: italic;
    font-weight: normal;
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
  textarea:-moz-placeholder {
    font-family: 'Dasa Sans';
    font-style: italic;
    font-weight: normal;
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  ${media.lessThan('medium')`
    display: block;
  `}
`

export const SubmitButton = styled.button`
  background: ${props => props.theme.colors.secondary};
  color: ${props => props.theme.colors.white};
  border: none;
  border-radius: 2px;
  cursor: ${props => ['pointer', 'auto'][Number(props.disabled)]};
  width: 21.8rem;
  padding: 1.6rem;
  opacity: ${props => ['1', '0.5'][Number(props.disabled)]};
  font-weight: normal;
  font-size: 1.6rem;
  line-height: 2%.4rem;
  opacity: 0.8;

  &:hover {
    opacity: 1;
  }

  ${media.lessThan('medium')`
    width: 100%;
    opacity: 1;
  `}
`

// SELECT
export const SelectContainer = styled.div`
  max-width: 30rem;
  height: 100%;
  position: relative;
  font-size: 1.6rem;
  line-height: 2.4rem;

  ${media.lessThan('medium')`
    width: 100%;
  `}
`

export const Select = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid ${props => props.theme.colors.brownishGrey};
`

export const SelectorTextWrapper = styled.p`
  margin: 0;
  white-space: nowrap;
  color: ${props => props.theme.colors.brownishGrey};
  font-size: 1.6rem;
  line-height: 2.4rem;
  overflow-x: hidden;

  ${media.lessThan('medium')`
    font-size: 1.4rem;
  `}
`

export const SelectArrowIcon = styled(KeyboardArrowDown)`
  color: ${props => props.theme.colors.brownishGrey};
  cursor: pointer;
  z-index: 10;
  position: absolute;
  right: 0;
  transform: rotate(${props => ['0deg', '180deg'][Number(props.flipped)]});
`

export const SelectOptions = styled.ul`
  display: ${props => ['none', 'block'][Number(props.visible)]};
  width: 100%;
  list-style-type: none;
  margin: 0;
  padding: 0;
  border-radius: 2px;
  position: absolute;
  background-color: ${props => props.theme.colors.white};
  overflow-y: scroll;
  overflow-y: overlay;
  z-index: 11;

  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);

  ::-webkit-scrollbar {
    width: 1.4rem;
    height: 1.8rem;
  }
  ::-webkit-scrollbar-thumb {
    height: 0.6rem;
    border: 0.4rem solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    -webkit-border-radius: 0.8rem;
    background-color: rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
      inset 1px 1px 0px rgba(0, 0, 0, 0.05);
  }
  ::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
  }
  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }
`

export const Option = styled.li`
  font-size: 1.6rem;
  line-height: 2.4rem;
  cursor: pointer;
  color: ${props => props.theme.colors.greyishBrown};
  padding: 0.5rem 1rem;

  &:hover {
    background-color: ${props => props.theme.colors.secondary};
    color: ${props => props.theme.colors.black};
  }

  ${media.lessThan('medium')`
    padding: 1.5rem 1rem;
  `}
`

export const OptionText = styled.p`
  font-size: 1.6rem;
  line-height: 2.4rem;
  max-width: 90%;
  margin-top: 0;
  margin-bottom: 0;
`

export const RecaptchaContainer = styled.div`
  margin: 1.6rem 0 3rem;

  ${media.lessThan('medium')`
    div {
      max-width: 100% !important;
    }

    iframe {
      transform: scale(0.7);
      -webkit-transform: scale(0.7);
      transform-origin: 0 0;
      -webkit-transform-origin: 0 0;
    }
  `}
`

// VALIDATION
export const MessageValidation = styled.p`
  margin: 0.5rem 0;
  color: red;

  ${media.lessThan('medium')`
    font-size: 1.1rem;
  `}
`

export const SendFormErrorMessage = styled.p`
  white-space: pre-line;
  margin: 0.5rem 0;
  color: red;
  width: 21.8rem;
  font-size: 1.2rem;

  ${media.lessThan('medium')`
    font-size: 1.4rem;
  `}
`
