import React, { memo } from 'react'
import * as S from './styled'

const ResponseMessage = ({ responseText, info, textLink, link }) => (
  <S.ResponseWrapper data-testid="success-response">
    <S.ResponseMainText>{responseText}</S.ResponseMainText>
    <S.ResponseText>
      {info} <a href={link}>{textLink}</a>
    </S.ResponseText>
  </S.ResponseWrapper>
)

export default memo(ResponseMessage)
