import React, { memo } from 'react'
import { SectionTitle } from '../styled'
import { CardContainer, ButtonsConatiner, ButtonContainer, CardRegionName } from './styled'
import CardInfo from './CardInfo'
import CardButton from './CardButton'

const Card = ({
  title,
  info,
  spUrl,
  spButtonText,
  rjUrl,
  rjButtonText,
  dfUrl,
  dfButtonText,
  target,
  isWhatsapp,
}) => (
  <CardContainer>
    <SectionTitle>{title}</SectionTitle>
    <CardInfo text={info} />
    <ButtonsConatiner>
      <ButtonContainer>
        <CardRegionName>São Paulo</CardRegionName>
        <CardButton url={spUrl} text={spButtonText} target={target} isWhatsapp={isWhatsapp} />
      </ButtonContainer>
      <ButtonContainer>
        <CardRegionName>Rio de Janeiro</CardRegionName>
        <CardButton url={rjUrl} text={rjButtonText} target={target} isWhatsapp={isWhatsapp} />
      </ButtonContainer>
      <ButtonContainer>
        <CardRegionName>Distrito Federal</CardRegionName>
        <CardButton url={dfUrl} text={dfButtonText} target={target} isWhatsapp={isWhatsapp} />
      </ButtonContainer>
    </ButtonsConatiner>
  </CardContainer>
)

export default memo(Card)
