import React, { memo } from 'react'
import WhatsappLogo from '../../../assets/images/whatsapp-logo.png'
import * as S from './styled'

const CardButton = ({ url, text, target, isWhatsapp }) => (
  <S.CardButton href={url} target={target}>
    {isWhatsapp && <S.CardWhatsappIcon src={WhatsappLogo} />}
    {text}
  </S.CardButton>
)

export default memo(CardButton)
