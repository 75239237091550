import * as yup from 'yup'

const contactFormSchema = yup.object().shape({
  region: yup.string().required('Selecione uma opção'),
  contactReason: yup.string().required('Selecione uma opção'),
  name: yup.string().required('Este é um campo obrigatório'),
  email: yup.string().email('Preencha um e-mail válido').required('Este é um campo obrigatório'),
  phone: yup
    .string()
    .required('Este é um campo obrigatório')
    .min(14, 'Digite um telefone válido')
    .max(15, 'Digite um telefone válido'),
  message: yup.string().required('Este é um campo obrigatório'),
  recaptcha: yup.string().nullable().required('Selecione o recaptcha'),
})

export default contactFormSchema
