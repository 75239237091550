import React, { useState } from 'react'
import { graphql } from 'gatsby'
import { formatTextToNumbers } from '@cms/utils'

import Layout from '../components/layout'
import SEO from '../components/seo'

import { Container } from '../components/shared/container'
import { PageTitle, PageInfo, CardsSection, FormSection } from '../components/Contact/styled'
import { Card, ContactForm, ResponseMessage } from '../components/Contact'

const FaleConosco = ({ data, pageContext, location }) => {
  const [form, setForm] = useState('contactForm')

  const changeFormType = formType => {
    setForm(formType)
  }

  const contactGatsbyPage = data.wordpressWpGatsbyPage.acf
  const settings = data.wordpressAltaThemeSettings

  const phoneCard = {
    title: contactGatsbyPage.cards.first_card.title,
    info: contactGatsbyPage.cards.first_card.info,
    spButtonText: contactGatsbyPage.cards.first_card.sp_button_text,
    spButtonLink: `tel:+55${formatTextToNumbers(settings.phone_sp)}`,
    rjButtonText: contactGatsbyPage.cards.first_card.rj_button_text,
    rjButtonLink: `tel:+55${formatTextToNumbers(settings.phone_rj)}`,
    dfButtonText: contactGatsbyPage.cards.first_card.df_button_text,
    dfButtonLink: `tel:+55${formatTextToNumbers(settings.phone_df)}`,
  }

  const whatsappCard = {
    title: contactGatsbyPage.cards.second_card.title,
    info: contactGatsbyPage.cards.second_card.info,
    spButtonText: contactGatsbyPage.cards.second_card.sp_button_text,
    spButtonLink: settings.whatsapp_link_sp,
    rjButtonText: contactGatsbyPage.cards.second_card.rj_button_text,
    rjButtonLink: settings.whatsapp_link_rj,
    dfButtonText: contactGatsbyPage.cards.second_card.df_button_text,
    dfButtonLink: settings.whatsapp_link_df,
  }

  const forms = {
    success: (
      <ResponseMessage
        responseText="Sua mensagem foi enviada com sucesso!"
        info="Nossa equipe responderá o mais breve possivel."
        textLink="Continuar navegando"
        link="/"
      />
    ),
    contactForm: (
      <>
        <PageTitle>{contactGatsbyPage.contact_page_title}</PageTitle>
        <PageInfo dangerouslySetInnerHTML={{ __html: contactGatsbyPage.contact_page_info }} />
        <CardsSection>
          <Card
            title={phoneCard.title}
            info={phoneCard.info}
            spUrl={phoneCard.spButtonLink}
            spButtonText={phoneCard.spButtonText}
            rjUrl={phoneCard.rjButtonLink}
            rjButtonText={phoneCard.rjButtonText}
            dfUrl={phoneCard.dfButtonLink}
            dfButtonText={phoneCard.dfButtonText}
          />
          <Card
            title={whatsappCard.title}
            info={whatsappCard.info}
            spUrl={whatsappCard.spButtonLink}
            spButtonText={whatsappCard.spButtonText}
            rjUrl={whatsappCard.rjButtonLink}
            rjButtonText={whatsappCard.rjButtonText}
            dfUrl={whatsappCard.dfButtonLink}
            dfButtonText={whatsappCard.dfButtonText}
            target="_blank"
            isWhatsapp
          />
        </CardsSection>
      </>
    ),
  }

  return (
    <Layout pageContext={pageContext} location={location}>
      <SEO
        title={`${contactGatsbyPage.title} | ${settings.brand_name}`}
        description={contactGatsbyPage.description}
        canonical={contactGatsbyPage.canonical}
      />
      <Container>{forms[form]}</Container>
    </Layout>
  )
}

export default FaleConosco

export const pageQuery = graphql`
  {
    wordpressWpGatsbyPage(slug: { eq: "fale-conosco" }) {
      acf {
        title
        description
        canonical
        contact_page_title
        contact_page_info
        cards {
          first_card {
            title
            info
            sp_button_text
            rj_button_text
            df_button_text
          }
          second_card {
            title
            info
            sp_button_text
            rj_button_text
            df_button_text
          }
        }
      }
    }
    wordpressAltaThemeSettings {
      brand_name
      brand_url
      phone_sp
      whatsapp_link_sp
      phone_rj
      whatsapp_link_rj
      phone_df
      whatsapp_link_df
    }
  }
`
