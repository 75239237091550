import styled from 'styled-components'

export const ResponseWrapper = styled.div`
  margin: 12rem 0;
`

export const ResponseMainText = styled.h1`
  font-family: 'Dasa Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 4.8rem;
  line-height: 5.6rem;
  color: ${props => props.theme.colors.tertiary};
`

export const ResponseText = styled.p`
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: ${props => props.theme.colors.brownishGrey};

  a {
    color: ${props => props.theme.colors.tertiary};
  }
`
