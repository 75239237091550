import styled from 'styled-components'
import media from 'styled-media-query'

export const PageTitle = styled.h1`
  font-family: 'Dasa Sans';
  font-size: 4rem;
  line-height: 4.8rem;
  font-weight: normal;
  color: ${props => props.theme.colors.secondary};
`

export const PageInfo = styled.div`
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: ${props => props.theme.colors.brownishGrey};
  margin-bottom: 1.6rem;
`

export const CardsSection = styled.section`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2.4rem;

  ${media.lessThan('large')`
    grid-template-columns: 1fr;
  `}
`

export const FormSection = styled.section`
  margin: 2.4rem 0 9.6rem;

  ${media.lessThan('medium')`
    margin-bottom: 6.4rem;
  `}
`

export const SectionTitle = styled.h2`
  margin: 0;
  margin-bottom: 1.6rem;
  font-family: 'Dasa Sans';
  font-size: 2.4rem;
  font-weight: normal;
  line-height: 3.2rem;
  color: ${props => props.theme.colors.black};
`
